<template>
    <div class="container-fluid">
        <headerDesign name="Geo Management"
                      logo="https://adretreaver.com/wp-content/uploads/2021/03/AdRlogo-500.png"
                      button-show="true"
                      button-name="Creative Management"
                      class="mb-4"></headerDesign>



        <div class="row p-2" style="background-color: #EBEBEB">
            <div class="col-4">
                <b-input-group>
                    <b-form-input style="border-radius: 0px !important;"></b-form-input>
                    <b-input-group-append>
                        <b-button style="border-radius: 0px !important;" variant="dark"><b-icon-search>

                        </b-icon-search></b-button>
                    </b-input-group-append>
                </b-input-group>
            </div>
            <div class="col-8 d-flex justify-content-end">
                <span class="mt-1"><b-icon-sliders font-scale="1.5"></b-icon-sliders> Filters</span>
                <b-button variant="dark" class="ml-4" >Save</b-button>
            </div>

        </div>
        <div class="row border-bottom">
            <div class="col-12 col-md-12">
                <p class="m-2"><strong>Client Name:</strong></p>
            </div>
        </div>
        <div class="row">
            <div class="col-4 col-md-4">
                <p class="m-2"><strong>Plan Type:</strong></p>
            </div>
            <div class="col-4 col-md-4">
                <p class="m-2"><strong>Proxy Type:</strong></p>
            </div>
            <div class="col-4 col-md-4">
                <p class="m-2"><strong>Plan Size:</strong></p>
            </div>
        </div>


        <b-table
            style="background-color: #ffffff"
            :items="item"
            :fields="field"
            responsive="sm"
            ref="selectableTable"
            selectable
            class="text-center"


        >
            <template v-slot:cell(Percentage_Per_Clicks)="row">
                <div class="col-12">
                    <ejs-slider id="slider"  min=0 max=100 step=1 value=30 :ticks='ticks' :tooltip='tooltip'>
                    </ejs-slider>
                </div>

            </template>
        </b-table>

        <b-modal id="modalPopover"  hide-header hide-footer  >

            <b-container >
                <h6 class="text-center p-2">
                    Upload Creative
                </h6>
                <p>Select and Upload email file:</p>
                <div id="dropArea">
                    <ejs-uploader ref="uploadObj" id='defaultfileupload' name="UploadFiles" :asyncSettings= "path"
                                  :autoUpload= 'autoUpload' :uploading="onUploadBegin"></ejs-uploader>
                </div>

                <b-button class="text-danger float-right mt-3" @click="hideUploadModal" variant="outline-light">
                    Cancel</b-button>
            </b-container>


        </b-modal>

        <footerDesign  first-button="Add a Product" first-button-show="false"  second-button="Manage Domains"
                       second-button-show="true" third-button="Manage Products" class="mt-4">

        </footerDesign>

</div>
</template>

<script>
import footerDesign from "@/components/style_Component/footerDesign";
import headerDesign from "@/components/style_Component/headerDesign";
import {BIconSearch, BIconSliders} from "bootstrap-vue";
import { SliderPlugin } from "@syncfusion/ej2-vue-inputs";

import Vue from "vue";
Vue.use(SliderPlugin);

export default {
    name: "geoConfiguration",
    components: {
        BIconSearch,
        BIconSliders,
        footerDesign,
        headerDesign,
    },


    data(){
        return{
            tooltip: { isVisible: true,  },
            ticks: { placement: 'After' },
            field: ['Country','State','City','Percentage_Per_Clicks','Total_Clicks'],
            item:[{Country:'US', State:'AL', City:'Adamsville',Percentage_Per_Clicks:'',Total_Clicks:'20000'},
                {Country:'US', State:'AL', City:'Boligee',Percentage_Per_Clicks:'',Total_Clicks:'24000'}
            ]
        }
    },
    created() {
        this.$root.preloader = false;
    },
}
</script>

<style scoped>




</style>
